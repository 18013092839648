import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Message ,Whisper, Tooltip, Popover, Button  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link, useNavigate } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import { ReactComponent as IconStar } from "../images/IconStar.svg";
import debounce from 'lodash.debounce';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import Open_Link_Icon from "../images/Open_link_round.svg";
import IconJson from "../images/IconJSONAction.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';
//import { Center } from '@chakra-ui/react';


function CompanyProfile() {
  const [tabName, setTabName] = useState('Company Profile');
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);


  useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const navigate = useNavigate();
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;
  const abortControllerRef = useRef(null);

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [pageRange, setPageRange] = useState("20");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info




  const handleSearch = (event) => {
    console.log(event.target.value);
  
    setSearchInput(event.target.value);
   // setCurrentPage(1); // Reset to the first page for new search
  };



const handleClearFilters = () => {
  setSearchInput("");
};


const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 4000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};






  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Student Listing</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>


          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
        <div className='header_monthly_value'>
            <Link to='/AddStudent' className='btn_add_new'><i className='icon-plus'></i>Add New Student</Link>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
       

<>
          <div className='table-responsive clsPagingWrap' >
            <table className='table'>
              <thead>
                <tr>
                  <th>Admission For</th>
                  <th>Name </th>
                  <th>Contact Number</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Age</th>
                  <th>Months</th>
                  <th>Address</th>
                  <th>School name</th>
                  <th>STD</th>
                  <th>Blood Group</th>
                </tr>
              </thead>
              <tbody>
              {/* {reportData.length > 0 ? ( reportData.map((company, index) => ( */}
                  <tr>
                    <td>Cricket</td>
                    <td>Rohit Patil</td>
                    <td>9403856909</td>
                    <td>imrohi21@gmail.com</td>
                    <td>21-01-1990</td>
                    <td>34</td>
                    <td>12</td>
                    <td>Vedantaa Avenue</td>
                    <td>Test</td>
                    <td>7</td>
                    <td>B+</td>
                  </tr>
                  <tr>
                    <td>Cricket</td>
                    <td>Rohit Patil</td>
                    <td>9403856909</td>
                    <td>imrohi21@gmail.com</td>
                    <td>21-01-1990</td>
                    <td>34</td>
                    <td>12</td>
                    <td>Vedantaa Avenue</td>
                    <td>Test</td>
                    <td>7</td>
                    <td>B+</td>
                  </tr>
                  <tr>
                    <td>Cricket</td>
                    <td>Rohit Patil</td>
                    <td>9403856909</td>
                    <td>imrohi21@gmail.com</td>
                    <td>21-01-1990</td>
                    <td>34</td>
                    <td>12</td>
                    <td>Vedantaa Avenue</td>
                    <td>Test</td>
                    <td>7</td>
                    <td>B+</td>
                  </tr>
                  <tr>
                    <td>Cricket</td>
                    <td>Rohit Patil</td>
                    <td>9403856909</td>
                    <td>imrohi21@gmail.com</td>
                    <td>21-01-1990</td>
                    <td>34</td>
                    <td>12</td>
                    <td>Vedantaa Avenue</td>
                    <td>Test</td>
                    <td>7</td>
                    <td>B+</td>
                  </tr>
                  <tr>
                    <td>Cricket</td>
                    <td>Rohit Patil</td>
                    <td>9403856909</td>
                    <td>imrohi21@gmail.com</td>
                    <td>21-01-1990</td>
                    <td>34</td>
                    <td>12</td>
                    <td>Vedantaa Avenue</td>
                    <td>Test</td>
                    <td>7</td>
                    <td>B+</td>
                  </tr>




            {/* //     ))
            //   ) : (
            //     <tr>
            //       <td colSpan="9" style={{ textAlign: "center" }}>
            //         No data available
            //       </td>
            //     </tr>
            //   )} */}
              </tbody>
            </table>
          </div>

          {/* {reportCount > 0 && renderPagination()} */}

        </>    

        </div>
      </div>

    </>
  );
}

export default CompanyProfile;
