import React, { useState, useRef, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, DatePicker, Button} from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import 'rsuite/DatePicker/styles/index.css';
import { useParams, useNavigate } from "react-router-dom";
 import { ChakraProvider, useToast, Wrap } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

function AddStudent() {
  const timeZone = 'Asia/Kolkata';
    const [tabName, setTabName] = useState('Add Student');
    const navigate = useNavigate();
    const location = useLocation();
  const id = location.state?.id;
  const buttonLabel = id ? 'Update' : 'Save';
  console.log('COMPANY ID : '+ id);

  
    const userid = localStorage.getItem("novauserid");
    useEffect(() => {
      document.title = tabName;
    }, [tabName]);

    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

    const [saveButtonLoader, setSaveButtonLoader] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [noOfEmp, setNoOfEmp] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [websiteURL, setWebsiteURL] = useState('');
    const [logo, setLogo] = useState('');
    const [currency, setCurrency] = useState([]);
    const summaryRef = useRef(null);
    
    const [exchange, setExchange] = useState('');
    const [exchangeSymbol, SetExchangeSymbol] = useState('');
    const [companySymbol, setCompanySymbol] = useState('');
    const [mainTicker, setMainTicker] = useState('');
    const [noOfOutstanding, setNoOfOutstanding] = useState('');

    const [forexRate, setForexRate] = useState('');
    const [marketCapitalization, setMarketCapitalization] = useState('');
    const [mcUSD, setMcUSD] = useState('');
    const [marketClassification, setMarketClassification] = useState('');
    const [exchangeCountry, setExchangeCountry] = useState([]);
    const [countryCode, setCountryCode] = useState('');

    const [mcCurrency, setMcCurrency] = useState('');
    const [ipoDate, setIpoDate] = useState('');
    const [isin, setIsin] = useState('');
    const [cusip, setCusip] = useState('');
    const [sedol, setSedol] = useState('');

    const [industryClassification, setIndustryClassification] = useState([]);
    const [icValue, setICValue] = useState('');
    const [sector, setSector] = useState([]);
    const [sectorval, setSectorval] = useState('');
    const [industryGroup, setIndustryGroup] = useState('');
    const [industry, setIndustry] = useState('');
    const [subindustry, setSubIndustry] = useState('');
    const [NAICSIndustry, setNAICSIndustry] = useState('');
    const [NAICSNationalIndustry, setNAICSNationalIndustry] = useState('');
    const [NAICSSector, setNAICSSector] = useState('');
    const [NAICSSubSector, setNAICSSubSector] = useState('');

// DATEPICKER
const [date, setDate] = useState(new Date());

// handle onChange event of the dropdown
const handleChange = (value) => {
  setDate(value);
};

function CustomCaretIcon() {
    return <i className='icon-calendar'></i>;
}

// FILE UPLOAD
const [fileName, setFileName] = useState("");
const fileInputRef = useRef(null);

const handleFileChange = (event) => {
  setFileName(event.target.files[0].name);
};

const handleClick = () => {
  fileInputRef.current.click();
};
// END FILE UPLOAD

const data = ['India', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
    item => ({ label: item, value: item })
);

const data2 = ['+91', '+92', '+93'].map(
    item => ({ label: item, value: item })
);

const marketCapClassification = [
    { label: "Small Cap", value: "SMALL_CAP" },
    { label: "Mid Cap", value: "MID_CAP" },
    { label: "Large Cap", value: "LARGE_CAP" },
    { label: "Mega Cap", value: "MEGA_CAP" },
  ];


const handleClose = async () => {
    navigate("/company-profile");
  };


  const toast = useToast()
  const toastIdRef = React.useRef()

//   const handleSave = () => {

    
//     toastIdRef.current = toast({
//       description: 'Please Add Company',
//       status: 'error',
//       duration: 5000, 
//       isClosable: true,
//       position: 'bottom-left'
//     })

//     toastIdRef.current = toast({
//         description: 'Please Add Market Cap',
//         status: 'error', // Set the status to "error" to show an error toast
//         duration: 5000,  // Duration in milliseconds
//         isClosable: true,
//         position: 'bottom-left'
//       })
//   }


const handleCurrencyChange = (value) => {
  setMcCurrency(value);
};

useEffect(() => {
  const fetchCurrencyList = async () => {
    const url = `${apiEndPoint}/get_forex_list`;
    const payload = {
      data: {
        user_id: userid // Replace with the actual user_id if dynamic
      }
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      const formatterForexData = data.data.map((forex) => ({
        label: forex.quote_currency,
        value: forex.quote_currency,
      }));

      setCurrency(formatterForexData);

    } catch (error) {
      console.error("get_forex_list API :", error.message);
      toastIdRef.current = toast({ description: error.message, status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      // setErrorMessage(error.message);
      // setMessageType('error');
      return false;
    }
  };

  fetchCurrencyList();
}, []);

const handleClassificationChange = (selectedOption) => {
    setMarketClassification(selectedOption)
  };


  const handleIndustryClassificationChange = (selectedOption) => {
    setICValue(selectedOption)
  };

  const handleSectorChange = (selectedOption) => {
    setSectorval(selectedOption)
  };

  
  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = ` ${apiEndPoint}/get_all_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setExchangeCountry(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);


  
  const handleCountryChange = (value) => {
    setCountryCode(value);
  };

    //Finhubb Industry Classification List
    useEffect(() => {
      const fetchCountryList = async () => {
        const url = ` ${apiEndPoint}/get_finnhub_industry_list`;
        const payload = {
          data: {
            user_id: userid // Replace with the actual user_id if dynamic
          }
        };
    
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const responseData = await response.json();
          const data = responseData.data; // Flatten the response
  
          const formattedIndustryData = data.data.map((industry) => ({
            label: industry.industry_name,
            value: industry.industry_name,
          }));
  
          setIndustryClassification(formattedIndustryData);
    
        } catch (error) {
          console.log(error);
        }
      };
    
      fetchCountryList();
    }, []);



    //Sector List
    useEffect(() => {
      const fetchCountryList = async () => {
        const url = ` ${apiEndPoint}/get_sector_list`;
        const payload = {
          data: {
            user_id: userid // Replace with the actual user_id if dynamic
          }
        };
    
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const responseData = await response.json();
          const data = responseData.data; // Flatten the response
  
          const formattedSectorData = data.data.map((sector) => ({
            label: sector.sector_name,
            value: sector.sector_name,
          }));
  
          setSector(formattedSectorData);
    
        } catch (error) {
          console.log(error);
        }
      };
    
      fetchCountryList();
    }, []);
  const handleSave = async () => {
    // Create a flag to check if there are any errors
    let hasError = false;
  
    // Check if the companyName field is empty
    if (!companyName) {
      toastIdRef.current = toast({ description: 'Please Add Company Name', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
    if (!address) {
      toastIdRef.current = toast({ description: 'Please Add Address', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
    if (!city) {
      toastIdRef.current = toast({ description: 'Please Add City', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
    if (!noOfEmp) {
      toastIdRef.current = toast({ description: 'Please Add No Of Employee', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
    // // Check if the state field is empty
    // if (!state) {
    //   toastIdRef.current = toast({ description: 'Please Add State', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the currency field is empty
    if (!mcCurrency) {
      toastIdRef.current = toast({ description: 'Please Select Currency', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the exchange field is empty
    if (!exchange) {
      toastIdRef.current = toast({ description: 'Please Add Exchange', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the exchangeSymbol field is empty
    if (!exchangeSymbol) {
      toastIdRef.current = toast({ description: 'Please Add Exchange Symbol', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the companySymbol field is empty
    if (!companySymbol) {
      toastIdRef.current = toast({ description: 'Please Add Company Symbol', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the mainTicker field is empty
    if (!mainTicker) {
      toastIdRef.current = toast({ description: 'Please Add Main Ticker', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the noOfOutstanding field is empty
    if (!noOfOutstanding) {
      toastIdRef.current = toast({ description: 'Please Add Number of Share Outstanding', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the forexRate field is empty
    // if (!forexRate) {
    //   toastIdRef.current = toast({ description: 'Please Add Forex Rate', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the marketCapitalization field is empty
    if (!marketCapitalization) {
      toastIdRef.current = toast({ description: 'Please Add Market Capitalization', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the mcUSD field is empty
    // if (!mcUSD) {
    //   toastIdRef.current = toast({ description: 'Please Add MC USD', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the marketClassification field is empty
    // if (!marketClassification) {
    //   toastIdRef.current = toast({ description: 'Please Add Market Classification', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the exchangeCountry field is empty
    if (!countryCode) {
      toastIdRef.current = toast({ description: 'Please Select Exchange Country', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // // Check if the mcCurrency field is empty
    // if (!mcCurrency) {
    //   toastIdRef.current = toast({ description: 'Please Add MC Currency', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the isin field is empty
    // if (!isin) {
    //   toastIdRef.current = toast({ description: 'Please Add ISIN', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
    //   hasError = true;
    // }
  
    // Check if the industryClassification field is empty
    if (!icValue) {
      toastIdRef.current = toast({ description: 'Please Select Industry Classification', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }
  
    // Check if the sector field is empty
    if (!sectorval) {
      toastIdRef.current = toast({ description: 'Please Select Sector', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
      hasError = true;
    }

    setSaveButtonLoader(true);
  
    // If there are no errors, proceed with the save logic
    if (!hasError) {
        const companyData = {
            data: {
              ...(id && { id: id }),
              user_id: userid,
              name: companyName,
              address: address,
              city: city,
              state: state,
              employeeTotal: noOfEmp,
              phone: phonenumber,
              weburl: websiteURL,
              logo: logo,
              currency: mcCurrency,
              description: summaryRef.current.value,
              exchange: exchange,
              exchange_symbol: exchangeSymbol,
              company_symbol: companySymbol,
              ticker: mainTicker,
              shareOutstanding: noOfOutstanding,
              //forex_rate: forexRate,
              marketCapitalization: marketCapitalization,
              //marketCapInUSD: mcUSD,
              //marketCapClassification: marketClassification,
              country: countryCode,
              marketCapCurrency: mcCurrency,
              ipo: ipoDate,
              isin: isin,
              cusip: cusip,
              sedol: sedol,
              finnhubIndustry: icValue,
              gsector: sectorval,
              ggroup: industryGroup,
              gind: industry,
              gsubind: subindustry,
              naics: NAICSIndustry,
              naicsNationalIndustry: NAICSNationalIndustry,
              naicsSector: NAICSSector,
              naicsSubsector: NAICSSubSector
            }
          };
        console.log(JSON.stringify(companyData));
        
          try {
            const response = await fetch(`${apiEndPoint}/add_company_profile`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(companyData),
            });
        
            const result = await response.json();
        
            if (response.ok) {
              // Check if the response contains an error status
              if (result.data.status === 'success') {
                setSaveButtonLoader(false);
                toastIdRef.current = toast({ description: result.data.message, status: 'success', duration: 5000, isClosable: true, position: 'bottom-left', });
                setTimeout(() => navigate("/company-profile"), 4000);
              }
              else if(result.data.status === 'error')
              {
                setSaveButtonLoader(false);
                toastIdRef.current = toast({ description: result.data.message, status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
                return false;
              }
            } else {
              setSaveButtonLoader(false);
              toastIdRef.current = toast({ description: 'An error occurred while adding the company profile', status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
              return false;
            }
          } catch (error) {
            setSaveButtonLoader(false);
            toastIdRef.current = toast({ description: error.message, status: 'error', duration: 5000, isClosable: true, position: 'bottom-left', });
            return false;
          }
    }
  };


  // Function to fetch company details
  const fetchCompanyDetails = async () => {
    const url = `${apiEndPoint}/get_company_details`;
    const data = {
      data: {
        user_id: userid,  // Replace with actual user ID
        id: id,  // Replace with actual company ID
        company_symbol: ""  // Optional: leave it blank or provide a symbol
      }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',  // Use 'POST' since you're sending data
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),  // Convert JavaScript object to JSON
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Parse the response body as JSON
      const result = await response.json();

      // Check if the 'status' field in the response is success
      if (result.data.status === 'success') {
        //setCompanyDetails(result.data.company_details);

        setCompanyName(result.data.company_details.name);
        setAddress(result.data.company_details.address);
        setCity(result.data.company_details.city);
        setState(result.data.company_details.state);
        setNoOfEmp(result.data.company_details.employeeTotal);
        setPhonenumber(result.data.company_details.phone);
        setWebsiteURL(result.data.company_details.weburl);
        setLogo(result.data.company_details.logo);
        setMcCurrency(result.data.company_details.currency);
        summaryRef.current.value = result.data.company_details.description
        
        setExchange(result.data.company_details.exchange);
        SetExchangeSymbol(result.data.company_details.exchange_symbol);
        setCompanySymbol(result.data.company_details.company_symbol);
        setMainTicker(result.data.company_details.ticker);
        setNoOfOutstanding(result.data.company_details.shareOutstanding);

        setMarketCapitalization(result.data.company_details.marketCapitalization);
        setCountryCode(result.data.company_details.country);
        const ipoDate = moment(result.data.company_details.ipo).tz(timeZone).format('YYYY-MM-DD');
        setIpoDate(new Date(ipoDate));
        setIsin(result.data.company_details.isin);
        setCusip(result.data.company_details.cusip);
        setSedol(result.data.company_details.sedol);

        setICValue(result.data.company_details.finnhubIndustry);
        setSectorval(result.data.company_details.gsector);
        setIndustryGroup(result.data.company_details.ggroup);
        setIndustry(result.data.company_details.gind);
        setSubIndustry(result.data.company_details.gsubind);
        setNAICSIndustry(result.data.company_details.naics);
        setNAICSNationalIndustry(result.data.company_details.naicsNationalIndustry);
        setNAICSSector(result.data.company_details.naicsSector);
        setNAICSSubSector(result.data.company_details.naicsSubsector);

      } else {
        // If the status is not success, set the error
        //setError(result.data.message);
      }

    } catch (error) {
      console.error('Error fetching company details:', error);
      //setError('Error fetching company details');
    } finally {
      // Set loading to false after fetch attempt
      //setLoading(false);
    }
  };

  // useEffect to call fetchCompanyDetails on component mount
  useEffect(() => {
    if (id) {  // Only call fetchCompanyDetails if `id` is truthy (not undefined or null)
      fetchCompanyDetails();
    }
  }, [id]);

  

//   const close = () => {
//     if (toastIdRef.current) {
//       toast.close(toastIdRef.current)
//     }
//   }


  return (
    <>
    <Sidebar/>

    {/* <ChakraProvider>
      <Wrap>
        <Button onClick={addToast}>Toast</Button>
      </Wrap>
    </ChakraProvider> */}

        <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>Add Student</div>
        </div>

        <div className=''>
            <ul className='add_company_btn_list'>
                <li><a href='#' className='btn_disabled'>Uncovered</a></li>
                <li><a href='#' className='btn_unpublished' style={{display:'none'}}>Unpublished</a></li>
                <li><a href='#' className='btn_published' style={{display:'none'}}>Published</a></li>
            </ul>
        </div>
    </div>
    
    <div className="container-fluid content pb-4">
      <div className='company_details_form'>
        <h2>Company Details</h2>

        <div className='form_details_group mt-3'>
            <div className='row'>
                <div className='col-sm-6 col-lg-3'>
                    <label className='label_form'>First Name<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </div>

                <div className='col-sm-6 col-lg-3'>
                    <label className='label_form'>Middle Name<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </div>

                <div className='col-sm-6 col-lg-3'>
                    <label className='label_form'>Last Name<span className='star_red'>*</span></label>
                    <input type='text' className='form-control' placeholder='Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </div>

                <div className='custom_col5'>
                        <label className='label_form'>DOB Date</label>
                        <div className='form_datepicker'>
                            <DatePicker oneTap style={{ width: "100%" }} placement="auto" placeholder="DD/MM/YYYY" caretAs={CustomCaretIcon}
                            value={ipoDate}
                            onChange={(date) => setIpoDate(date)}
                            />
                        </div>
                    </div>

            </div>
        </div>


        <div className='form_details_group mt-3'>
            <div className='row'>
                <div className='col-sm-6'>
                    <label className='label_form'>Address</label>
                    <textarea rows={2} className='form-control' ref={summaryRef}></textarea>
                </div>

                <div className='col-sm-6'>
                    <label className='label_form'>School Name</label>
                    <textarea rows={2} className='form-control' ref={summaryRef}></textarea>
                </div>

            </div>
        </div>


        

       
      </div>
    </div>

    <div className='add_new_btn_group'>
        <button className='btn_cancel_form' onClick={handleClose}>Cancel</button>
        <ChakraProvider>
      <Wrap>
        {/* <Button onClick={addToast}>Toast</Button> */}
        <Button className='btn_save_form' onClick={handleSave} loading={saveButtonLoader}>{buttonLabel}</Button>
      </Wrap>
    </ChakraProvider>

        
    </div>

    </>
  );
}

export default AddStudent;
