import React, { useState, useRef, useEffect } from "react";
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography } from "@mui/material";
import { SelectPicker, Placeholder } from 'rsuite';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [openSubMenu, setOpenSubMenu] = useState('');
  const sidebarRef = useRef(null);
  const roleName = localStorage.getItem('novaRolename');
  const currentLocation = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsCollapsed(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set the open submenu based on the current location
    if (currentLocation.pathname.startsWith("/compliance-reports") || currentLocation.pathname.startsWith("/company-profile") || currentLocation.pathname.startsWith("/StockReport")) {
      setOpenSubMenu('Stocks');
    } else if (currentLocation.pathname.startsWith("/ETFS") || currentLocation.pathname.startsWith("/ETFHoldingsBasket")) {
      setOpenSubMenu('ETFS');
    } else if (currentLocation.pathname.startsWith("/MarketsSettings") || currentLocation.pathname.startsWith("/Empty-ISINS") || currentLocation.pathname.startsWith("/UsersandRoles") ) {
      setOpenSubMenu('Settings');
    } else if (currentLocation.pathname.startsWith("/Logout")) {
      setOpenSubMenu('Account');
    } else {
      setOpenSubMenu('');
    }
  }, [currentLocation]);

  const handleMouseEnter = () => {
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    setIsCollapsed(false);
  };

  const handleMouseMove = () => {
    setIsCollapsed(false);
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsCollapsed(true); // Close the sidebar drawer
    }
  };

  // Add event listener to handle outside clicks
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Box onMouseMove={handleMouseMove}>
        <div id="sidebarTrigger" onClick={() => setIsCollapsed(!isCollapsed)}></div>
        <ProSidebar
          collapsed={isCollapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={sidebarRef}
          styles={{
            transition: 'transform 0.10s ease', // Adjust the transition duration here (0.5s)
            transform: isCollapsed ? 'translateX(-260px)' : 'translateX(0)'
          }}
        >
          <Menu iconShape="square">
            <Box>
              <Typography
                variant="h6"
                sx={{ m: "15px 0 5px 20px" }}
              >
                Mains ({roleName})
              </Typography>


              

              <MenuItem 
                className={selectedMenuItem === 'Dashboard' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('Dashboard'); setOpenSubMenu('Dashboard'); }}
                active={currentLocation.pathname === "/Dashboard"}
              >
                <i className="icon-home"></i> <Link to="/Dashboard">Dashboard</Link> 
              </MenuItem>
              
             
              <MenuItem 
                className={selectedMenuItem === 'ListingStudent' ? 'active' : ''} 
                onClick={() => { setSelectedMenuItem('ListingStudent'); setOpenSubMenu('ListingStudent'); }}
                active={currentLocation.pathname === "/ListingStudent"}
              >
                <i className="fa fa-file-text-o"></i> <Link to="/ListingStudent">Add Students</Link>
              </MenuItem> 
              
              <Typography
                variant="h6"
                sx={{ m: "15px 0 5px 20px" }}
                className="menu_setting_section"
              >
                Settings
              </Typography>
              <SubMenu 
                title={<><i className="custom-icon icon-user"></i> Account</>}
                open={openSubMenu === 'Account'} 
                onOpen={() => setOpenSubMenu('Account')} 
                onClick={() => setOpenSubMenu('Account')}
              >
                <MenuItem 
                  className={selectedMenuItem === 'Logout' ? 'active' : ''} 
                  onClick={() => { setSelectedMenuItem('Logout'); setOpenSubMenu('Logout'); localStorage.removeItem('isLoggedIn'); }}
                  active={currentLocation.pathname === "/"}
                >
                  <Link to="/">Logout</Link>
                </MenuItem>                                   
              </SubMenu> 

              <SubMenu title={<><i className="custom-icon icon-setting"></i> Settings</>} open={openSubMenu === 'Settings'} onOpen={() => setOpenSubMenu('Settings')} onClick={() => setOpenSubMenu('Settings')} >
           
              {roleName === 'MERLIN_ADMIN' && (
              <MenuItem className={selectedMenuItem === 'UsersandRoles' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/UsersandRoles"} >
                  <Link to="/UsersandRoles">Users And Roles</Link>
                </MenuItem> 
              )}

                <MenuItem className={selectedMenuItem === 'MarketsSettings' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/MarketsSettings"} >
                  <Link to="/MarketsSettings">Markets Settings</Link>
                </MenuItem> 
                <MenuItem className={selectedMenuItem === 'Empty-ISINS' ? 'active' : ''} onClick={() => { setSelectedMenuItem('Settings'); setOpenSubMenu('Settings'); }} active={currentLocation.pathname === "/Empty-ISINS"} >
                  <Link to="/Empty-ISINS">Finnhub-Musaffa Data</Link>
                </MenuItem>                                   
              </SubMenu> 
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </>
  );
};

export default Sidebar;
