import React, { useState, useEffect } from 'react'
//import Sidebar from './component/Sidebar';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ListingStudent from "./pages/ListingStudent";
import AddStudent from "./pages/AddStudent";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 

import "./css/style.css";
import LoadingBar from 'react-top-loading-bar';

//import $ from "jquery";


const App = () => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    //console.log(window.location.pathname);

    useEffect(() => {
        simulateDataLoad();
      }, [])

      const simulateDataLoad = () => {
        setProgress(30);
    
        // Simulating data loading
        setTimeout(() => {
          setProgress(70);
    
          setTimeout(() => {
            setProgress(100);
            setIsLoading(false);
          }, 1000);
        }, 2000);
      };


    return(
        <>
         <div> <LoadingBar color="#FCB633" progress={isLoading ? progress : 100} onLoaderFinished={() => setProgress(0)} /> </div>
        <Router basename='/'> 
         {/* {window.location.pathname !== '/nova' && <Sidebar />}
        <Sidebar/> */}
            <Routes> 
                <Route path="/" element={<Login />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/ListingStudent" element={<ListingStudent />} />
                <Route path="/AddStudent" element={<AddStudent />} />
                
            </Routes>        
        </Router>     
        </>     
    );
};

export default App;